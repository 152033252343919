/* eslint-disable */
//ページ毎のscript
class page {
  constructor() {
    this.init()
  }

  async init() {}
}

export default new page()
